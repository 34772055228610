<template>
  <div id="pc" class="etnersWrap wrapbg">
    <user-main-header-area></user-main-header-area>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
    <user-main-footer-area></user-main-footer-area>
  </div>
</template>

<script>
  import UserMainHeaderArea from '../fragments/UserMainHeaderArea'
  import UserMainFooterArea from '../fragments/UserMainFooterArea'

  export default {
    name      : '',
    components: {
      UserMainHeaderArea,
      UserMainFooterArea
    },
  }
</script>
