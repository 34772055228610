<template>
  <footer class="footer">
    <div class="top__btn" onclick="$(&#39;html, body&#39;).stop().animate( { scrollTop : 0 } ); "></div>
    <div class="inner">

      <div class="footer_cont">
        <ul class="first_text">
          <li><a href="https://etners.com/terms/management/detail/00/02" target="_blank" class="link_info"
              style="cursor:pointer"><b>개인정보처리방침</b></a>
          </li>
          <li><a href="https://etners.com/terms/management/detail/00/03" target="_blank" class="link_info"
                style="cursor:pointer"><b>고정형 영상정보처리기기 운영관리방침</b></a>
          </li>
          <li><a href="https://etners.com/terms/management/detail/00/04" target="_blank" class="link_info" style="cursor:pointer">법적고지 </a></li>
          <!--li><a href="/../notice.html" target="_blank" class="link_info" style="cursor:pointer">이메일무단수집거부 </a></li-->
        </ul>
        <div class="text">
          <div class="sub">
            경기도 과천시 과천대로7나길 60 과천어반허브 A동 5F (우.13840) <b>이트너스(주)</b>
            <em>대표전화 : 1533-4810 팩스 : 070-8806-5190 <span class="copy">© 2022 Etners Co., Ltd.</span></em>
          </div>
          <ul class="inline_wrap sns">
            <li><a href="https://blog.naver.com/etners" target="_blank">
                <img src="@/assets/img/blog_icon.svg" alt="블로그">
              </a></li>
            <li><a href="https://www.youtube.com/channel/UCplJe-DdR7EuGgy6WrNadHw" target="_blank">
                <img src="@/assets/img/yout.svg" alt="유튜브"></a></li>
          </ul>
        </div>
      </div>
      <div class="footer_right">
        <div class="footer_rcont">
          <select onchange="if(this.value) window.open(this.value);">
            <option selected="selected" value="">FAMILY SITE</option>
            <option value=" //etners.com/">ETNERS</option>
            <option value="//sharedservice.etners.com">SHARED SERVICE</option>
            <option value="//etnerspayroll.com">PAYROLL</option>
            <option value="//gamdongtime.etnersplatform.com/">GAMDONG TIME</option>
            <option value="//esop.etners.com">ESOP</option>
            <option value="//mall.etners.com">MALL</option>
            <option value="//moving.etners.com">MOVING</option>
            <option value="//housing.etners.com">HOUSING</option>
            <option value="//relocation.etners.com">RELOCATION</option>
            <option value="//bidding.etners.com/">BIDDING</option>
            <option value="//shop.etners.com">SHOP</option>
            <option value="//etnersefs.com/landing">EFS</option>
            <option value="//esrm.etners.com">ESRM</option>
          </select>
        </div>
        <div class="big">
          <img src="@/assets/img/logo.png" alt="">
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import ApiConfig from '../api/api.config'

export default {
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    fnChangeFamilySite: function (event) {
      let siteAddr = event.target.value
      if (siteAddr !== undefined) {
        window.open(siteAddr)
      }
    },

  },
  data: function () {

    return {

    }
  },

}
</script>
<style>

</style>
